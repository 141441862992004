var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.info.is_admin && !_vm.info.without_detail)?_c('NLink',{staticClass:"card-main-sidebar-coupon",attrs:{"to":`/${_vm.$util.lang.routes.coupons}/${_vm.info.slug}`,"rel":"follow"}},[_c('picture',{staticClass:"card-main-sidebar-coupon__image"},[_c('img',{attrs:{"src":_vm.info.featured_image || '',"alt":_vm.info.title,"title":_vm.info.title,"loading":"lazy","width":"80","height":"80","onerror":`this.onerror=null;this.src='${_vm.defaultImage.coupon}'`}})]),_vm._v(" "),_c('div',{staticClass:"right-wrapper"},[_c('h4',{staticClass:"card-main-sidebar-coupon__shop"},[_vm._v("\n            "+_vm._s(_vm.info.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-main-sidebar-coupon__info"},[(_vm.showNewPrice)?_c('p',{staticClass:"card-main-sidebar-coupon__new-price",class:{
                    'flex items-center space-x-1': _vm.isCoupon && _vm.info.discount_type === 'envío gratis',
                }},[(_vm.isCoupon && _vm.info.discount_type === 'envío gratis')?_c('img',{staticClass:"w-5 h-5",attrs:{"src":_vm.$assets.primary.shipment,"alt":"icon"}}):_vm._e(),_vm._v(" "),_c('span',{class:{
                        'text-site-primary text-sm font-normal':
                            _vm.isCoupon && _vm.info.discount_type === 'envío gratis',
                    }},[_vm._v(_vm._s(_vm.newPrice))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.$util.lang.components.cardMainSidebarDiscount.see_coupon))])])])]):_c('div',{staticClass:"card-main-sidebar-coupon",attrs:{"rel":"follow"},on:{"click":_vm.openCoupon}},[_c('picture',{staticClass:"card-main-sidebar-coupon__image is-admin"},[(_vm.info.store && _vm.info.store.image)?_c('img',{attrs:{"src":_vm.info.store.image.url,"alt":_vm.info.title,"title":_vm.info.title,"loading":"lazy","width":"80","height":"80","onerror":`this.onerror=null;this.src='${_vm.defaultImage.coupon}'`}}):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"right-wrapper"},[_c('h4',{staticClass:"card-main-sidebar-coupon__shop"},[_vm._v("\n            "+_vm._s(_vm.info.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-main-sidebar-coupon__info"},[(_vm.showNewPrice)?_c('p',{staticClass:"card-main-sidebar-coupon__new-price",class:{
                    'flex items-center space-x-1': _vm.isCoupon && _vm.info.discount_type === 'envío gratis',
                }},[(_vm.isCoupon && _vm.info.discount_type === 'envío gratis')?_c('img',{staticClass:"w-5 h-5",attrs:{"src":_vm.$assets.primary.shipment,"alt":"icon"}}):_vm._e(),_vm._v(" "),_c('span',{class:{
                        'text-site-primary text-sm font-normal':
                            _vm.isCoupon && _vm.info.discount_type === 'envío gratis',
                    }},[_vm._v(_vm._s(_vm.newPrice))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"status"},[_vm._v(_vm._s(_vm.$util.lang.components.cardMainSidebarDiscount.see_coupon))])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }