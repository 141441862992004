
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CardMainSidebarDiscount',
    props: {
        info: Object as PropType<Models.Discount>,
    },
    data() {
        return {
            defaultImage,
            isOffer: this.info.type === 'offer',
            isCoupon: this.info.type === 'coupon',
        }
    },
    computed: {
        showNewPrice(): boolean {
            return (
                (this.isOffer && !!this.info.new_price) ||
                (this.isCoupon &&
                    (((this.info.discount_type === 'precio' || this.info.discount_type === 'porcentaje') &&
                        this.info.discount_value !== '0') ||
                        this.info.discount_type === 'envío gratis'))
            )
        },
        newOfferPrice(): string {
            return this.info.new_price === '0' ? 'GRATIS' : this.$util.lang.symbol + this.info.new_price
        },
        newCouponPrice(): string {
            return this.info.discount_type === 'envío gratis'
                ? this.$util.lang.components.cardMainSidebarDiscount.free_ship
                : this.info.discount_type === 'precio'
                ? this.$util.lang.symbol + this.info.discount_value + ' OFF'
                : this.info.discount_value === '100'
                ? this.$util.lang.components.cardMainSidebarDiscount.free
                : this.info.discount_value + '% OFF'
        },
        newPrice(): string {
            return (this.isOffer && this.newOfferPrice) || (this.isCoupon && this.newCouponPrice) || ''
        },
    },
    methods: {
        openCoupon() {
            if (this.info.is_admin || this.info.without_detail) {
                this.$openCoupon(this.info.slug, this.info.without_detail ? '' : this.info.store?.slug || '')
            } else {
                this.$openCoupon(this.info.slug)
            }
        },
    },
})
