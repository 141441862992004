
import Vue from 'vue'

export default Vue.extend({
    name: 'FormFieldCheckbox',
    props: {
        value: Boolean,
        label: String,
        id: String,
        invalid: Boolean,
    },
    methods: {
        inputChangeHandler(e: Event) {
            const target = <HTMLInputElement>e.target

            this.$emit('input', target.checked)
        },
    },
})
