
import Vue, { PropType } from 'vue'
import { MetaInfo } from 'vue-meta'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardCtaWhatsapp',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
    },
    data() {
        return {
            hide: false,
            bg: this.$assets.illustration.cta.whatsapp.bg,
        }
    },
    head(): MetaInfo {
        const image1 = this.$assets.illustration.cta.whatsapp.logo
        const image2 = this.$assets.illustration.cta.whatsapp.bg
        return {
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: image1,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                            ? '(min-width: 1024px)'
                            : ''
                        : '',
                },
                {
                    rel: 'preload',
                    as: 'image',
                    href: image2,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                            ? '(min-width: 1024px)'
                            : ''
                        : '',
                },
            ],
        } as MetaInfo
    },
})
