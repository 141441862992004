
import Vue from 'vue'

export default Vue.extend({
    name: 'MicroHeatCounter',
    props: {
        count: {
            type: Number,
            required: true,
        },
        textStyle: {
            type: String,
            default: 'text-sm',
        },
        iconStyle: {
            type: String,
            default: 'w-4 h-4 xl:h-5 xl:w-5',
        },
    },
    computed: {
        fire(): 'red' | 'orange' | 'gray' | 'blue' {
            return this.count > 100 ? 'red' : this.count > 50 ? 'orange' : this.count > 0 ? 'gray' : 'blue'
        },
        text(): string {
            return this.count > 100
                ? 'text-red-500'
                : this.count > 50
                ? 'text-orange-500'
                : this.count > 0
                ? 'text-gray-800'
                : 'text-sky-600'
        },
    },
})
