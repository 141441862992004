
import Vue, { PropOptions } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'SidebarFeaturedCoupons',
    props: {
        discounts: { type: Array } as PropOptions<Models.Discount[]>,
    },
})
