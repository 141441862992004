
import Vue, { PropType } from 'vue'

type TabItem = {
    title: string
    icon: {
        active: string
        inactive: string
    }
    amount: number
    goto: string
    to: string
}
type TabsListInfo = {
    tabs: Array<TabItem>
    show: string | number
}

export default Vue.extend({
    name: 'MicroTabs',
    props: {
        styling: {
            type: String,
            default: 'small',
        },
        info: {
            type: Object as PropType<TabsListInfo>,
            required: true,
        },
        slider: {
            type: Boolean,
            default: false,
        },
        orderby: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            dropdown: {
                show: {
                    orderby: false,
                },
            },
        }
    },
    computed: {
        newInfo(): TabsListInfo {
            return this.info
        },
    },
    methods: {
        changeSelectedItem(newSelected: any) {
            this.$emit('update:orderby', newSelected)
        },
        dropdownToggle(show: boolean, name: string): void {
            this.dropdown.show[name as 'orderby'] = show
        },
        currentTab(key: any) {
            this.newInfo.show = key
        },
        handleChangeRoute(to: string) {
            if (this.$route.path !== to) this.$router.push(to)
        },
        resolveHandler(tab: TabItem, key: number) {
            return tab.to ? () => this.handleChangeRoute(tab.to) : () => this.currentTab(key)
        },
    },
})
