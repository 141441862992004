
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'MicroCategoriesChips',
    props: {
        info: Array as PropType<Models.Category[]>,
    },
})
