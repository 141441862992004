
import Vue, { PropType } from 'vue'
import { parseISO, differenceInMinutes } from 'date-fns'
import { getDateDifference } from '~/libs/date-functions'
import { numberFormattedWithDots } from '@/libs/string-functions'
import { Models } from '@/types/models'
import { getDictionary } from '@/libs/variant-dict'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CardMainGlobalFeatured',
    props: {
        info: {
            type: Object as PropType<Models.Discount>,
            required: true,
        },
        lazyImage: Boolean,
        expire: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            saved: !!this.info.saved,
            numberFormattedWithDots,
            getDateDifference,
            defaultImage,
        }
    },
    computed: {
        inStoreRoute() {
            return this.$route.path.includes(`/${this.$util.lang.routes.brands}/`)
        },
        isOffer(): boolean {
            return this.info.type === 'offer'
        },
        isCoupon(): boolean {
            return this.info.type === 'coupon'
        },
        variant() {
            return getDictionary[this.info.type]
        },
        refreshedAt30MinsAgo(): number {
            const date = this.info.updated_at && parseISO(this.info.updated_at)
            const difference = !!date && differenceInMinutes(new Date(), date)

            return (difference && difference > 0 && difference < 30 && difference) || 0
        },
        isFreeShipping(): boolean {
            return this.info.discount_type === 'envío gratis' || !!this.info.shipping_free
        },
        showOldPrice(): boolean {
            return (
                this.isOffer && !!this.info.old_price && !!this.info.new_price && this.info.new_price !== '0'
            )
        },
        showNewPrice(): boolean {
            return (
                (this.isOffer && !!this.info.new_price) ||
                (this.isCoupon &&
                    (((this.info.discount_type === 'precio' || this.info.discount_type === 'porcentaje') &&
                        this.info.discount_value !== '0') ||
                        this.info.discount_type === 'envío gratis'))
            )
        },
        newOfferPrice(): string {
            return this.info.new_price === '0' ? 'GRATIS' : this.$util.lang.symbol + this.info.new_price
        },
        newCouponPrice(): string {
            return this.info.discount_type === 'envío gratis'
                ? this.$util.lang.components.cardMainGlobalFeatured.free_ship
                : this.info.discount_type === 'precio'
                ? this.$util.lang.symbol + this.info.discount_value + ' OFF'
                : this.info.discount_value === '100'
                ? this.$util.lang.components.cardMainGlobalFeatured.free
                : this.info.discount_value + '% OFF'
        },
        newPrice(): string {
            return (this.isOffer && this.newOfferPrice) || (this.isCoupon && this.newCouponPrice) || ''
        },
        showPercent(): boolean {
            return (
                !!this.info.old_price &&
                !!this.info.new_price &&
                !!this.info.discount_percent &&
                this.info.new_price !== '0'
            )
        },
        showPrices(): boolean {
            return this.showNewPrice
        },
        createdAt(): Date {
            return parseISO(this.info.created_at)
        },
        updatedAt(): Date | null {
            return this.info.updated_at ? parseISO(this.info.updated_at) : null
        },
        areTimestampsEqual(): boolean {
            return (
                !!this.createdAt &&
                !!this.updatedAt &&
                getDateDifference(this.createdAt) === getDateDifference(this.updatedAt)
            )
        },
        fullVersion(): boolean {
            return (
                this.$route.name === 'index' ||
                this.$route.name === 'index-comentados' ||
                this.$route.name === 'index-nuevos'
            )
        },
        tagClass(): { [x: string]: boolean } {
            return {
                offer: this.info.type === 'offer',
                coupon: this.info.type === 'coupon',
            }
        },
    },
    watch: {
        info: {
            deep: true,
            handler(info: Models.Discount) {
                this.saved = !!info.saved
            },
        },
    },
    methods: {
        openCoupon() {
            if (this.info.is_admin || this.info.without_detail) {
                this.$openCoupon(this.info.slug, this.info.without_detail ? '' : this.info.store?.slug || '')
            } else {
                this.$openCoupon(this.info.slug)
            }
        },
        openOffer() {
            this.$openOffer(this.info.id)
        },
        async savePost() {
            try {
                const saveResult = await this.$api.create.savePost({
                    saveable_id: this.info.id,
                    saveable_type: this.info.type,
                    type: this.saved ? 0 : 1,
                })

                if (saveResult?.feedback === 'data_success') {
                    this.saved = !this.saved
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardMainGlobalFeatured.okay,
                        text: `${
                            this.isOffer
                                ? this.$util.lang.components.cardMainGlobalFeatured.offer
                                : this.$util.lang.components.cardMainGlobalFeatured.coupon
                        } <span style="font-weight:600">${this.$util.textShortener(
                            this.info.title,
                            this.$bp.mb ? 30 : this.$bp.xs || this.$bp.sm ? 60 : 90
                        )}</span> ${
                            this.saved
                                ? this.isOffer
                                    ? this.$util.lang.components.cardMainGlobalFeatured.saved_a
                                    : this.$util.lang.components.cardMainGlobalFeatured.saved
                                : this.isOffer
                                ? this.$util.lang.components.cardMainGlobalFeatured.deleted_a
                                : this.$util.lang.components.cardMainGlobalFeatured.deleted
                        }`,
                        type: 'success',
                    })
                } else if (saveResult?.feedback === 'error_fields_validation') {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardMainGlobalFeatured.okay,
                        text:
                            saveResult.errors.type?.[0] ||
                            saveResult.errors.saveable_type?.[0] ||
                            saveResult.errors.id?.[0] ||
                            saveResult.errors.saveable_id?.[0] ||
                            `${this.$util.lang.components.cardMainGlobalFeatured.error_on} ${
                                this.saved
                                    ? this.$util.lang.components.cardMainGlobalFeatured.delete
                                    : this.$util.lang.components.cardMainGlobalFeatured.save
                            }, ${this.$util.lang.components.cardMainGlobalFeatured.something_happens} ${
                                this.isOffer
                                    ? this.$util.lang.components.cardMainGlobalFeatured.this_offer
                                    : this.$util.lang.components.cardMainGlobalFeatured.this_coupon
                            }.`,
                        type: 'error',
                    })
                } else if (
                    this.$store.getters['auth/isAuth'] &&
                    this.$store.state.user.profile?.accepted_conditions_at &&
                    this.$store.state.user.profile?.verified_email
                ) {
                    this.$root.$emit('notification', {
                        duration: 5000,
                        dismissButtonText: this.$util.lang.components.cardMainGlobalFeatured.okay,
                        text: this.$util.lang.components.cardMainGlobalFeatured.error_on_save,
                        type: 'error',
                    })
                }
            } catch (e) {}
        },
    },
})
