
import Vue from 'vue'

export default Vue.extend({
    name: 'MicroSlider',
    mounted() {
        const scrollArea = this.$refs.scrollArea as HTMLDivElement
        const navigationLeft = this.$refs.navigationLeft as HTMLButtonElement
        const navigationRight = this.$refs.navigationRight as HTMLButtonElement

        if (scrollArea?.scrollWidth === scrollArea?.clientWidth) {
            navigationLeft?.classList.add('hide')
            navigationRight?.classList.add('hide')
        }

        scrollArea?.addEventListener('scroll', () => {
            if (scrollArea.scrollLeft > 0) {
                if (navigationLeft?.classList.contains('hide')) {
                    navigationLeft?.classList.remove('hide')
                }
            } else {
                navigationLeft?.classList.add('hide')
            }

            if (scrollArea.scrollWidth - (scrollArea.scrollLeft + scrollArea.clientWidth) > 0) {
                if (navigationRight?.classList.contains('hide')) {
                    navigationRight?.classList.remove('hide')
                }
            } else {
                navigationRight?.classList.add('hide')
            }
        })

        navigationLeft?.addEventListener('click', () => {
            if (scrollArea && scrollArea.scrollLeft > 0) {
                if (scrollArea.scrollLeft > scrollArea?.clientWidth) {
                    scrollArea.scrollLeft = scrollArea.scrollLeft - scrollArea?.clientWidth
                } else {
                    scrollArea.scrollLeft = 0
                }
            }
        })
        navigationRight?.addEventListener('click', () => {
            if (scrollArea && scrollArea.scrollLeft < scrollArea.scrollWidth - scrollArea.clientWidth) {
                if (
                    scrollArea.scrollWidth - (scrollArea.scrollLeft + scrollArea.clientWidth) >
                    scrollArea?.clientWidth
                ) {
                    scrollArea.scrollLeft = scrollArea.scrollLeft + scrollArea?.clientWidth
                } else {
                    scrollArea.scrollLeft = scrollArea.scrollWidth - scrollArea.clientWidth
                }
            }
        })
    },
})
