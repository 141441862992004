
import Vue from 'vue'

export default Vue.extend({
    name: 'BtnSeeMoreResults',
    props: {
        handler: {
            type: Function,
            default: () => false,
        },
        loading: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
})
