
import Vue from 'vue'

export default Vue.extend({
    name: 'CardMainLazy',
    props: {
        ignoreLazy: Boolean,
        title: String,
        mode: String,
        /* url: String, */
    },
    data() {
        return {
            intersected: false,
        }
    },
    mounted() {
        if (!this.ignoreLazy) {
            this.$scrollObserver.addTarget(
                'lazyComponents',
                this.$refs.ScrollRoot as HTMLDivElement,
                this.intersectHandler
            )
        }
    },
    methods: {
        intersectHandler(data: { target: Element; intersected: boolean }) {
            if (data.intersected) {
                this.intersected = true

                this.$scrollObserver.remove(data.target)
            }
        },
    },
})
