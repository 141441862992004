
import Vue from 'vue'

import { PropOptions } from 'vue/types'

export default Vue.extend({
    name: 'BtnGeneric',
    props: {
        loading: { type: Boolean, default: false },
        info: Object as PropOptions<{
            text: String
            link: String
            handler: Function
            icon: String
            iconAlt: String
        }>,
    },
})
