
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'
import { defaultImage } from '~/libs/useful-functions'

export default Vue.extend({
    name: 'CardImagesGallery',
    props: {
        data: {
            type: Array as PropType<Models.Store[]>,
        },
    },
    data() {
        return {
            defaultImage,
        }
    },
})
