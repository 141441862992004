
import Vue, { PropType } from 'vue'
import { MetaInfo } from 'vue-meta'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CardCtaTelegram',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
    },
    data() {
        return {
            hide: false,
        }
    },
    head(): MetaInfo {
        const image = this.$assets.social.telegramFullImage
        return {
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: image,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                            ? '(min-width: 1024px)'
                            : ''
                        : '',
                },
            ],
        } as MetaInfo
    },
})
