
import Vue, { PropType } from 'vue'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CtaHandler',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
    },
    data() {
        return {
            expired: this.cta.expired_at ? new Date() > new Date(this.cta.expired_at) : false,
        }
    },
    computed: {
        viewportClasses(): any {
            return {
                block: this.cta.mobile && this.cta.desktop,
                'block lg:hidden': !(this.cta.mobile && this.cta.desktop) && this.cta.mobile,
                'hidden lg:block': !(this.cta.mobile && this.cta.desktop) && this.cta.desktop,
                hidden: !this.cta.mobile && !this.cta.desktop,
            }
        },
    },
})
