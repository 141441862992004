
import Vue, { PropType } from 'vue'
import { MetaInfo } from 'vue-meta'
import { Models } from '~/types/models'

export default Vue.extend({
    name: 'CTAExtAndApp',
    props: {
        cta: {
            type: Object as PropType<Models.CtaCard>,
            required: true,
        },
        mode: {
            type: String as PropType<'ext' | 'app'>,
            default: 'ext',
        },
    },
    data() {
        return {
            hide: false,
            extHead: this.$util.lang.components.ctaExtAndApp.extHead,
            extTitle: this.$util.lang.components.ctaExtAndApp.extTitle,
            extDescription: this.$util.lang.components.ctaExtAndApp.extDescription,
            appHead: this.$util.lang.components.ctaExtAndApp.appHead,
            appTitle: this.$util.lang.components.ctaExtAndApp.appTitle,
        }
    },
    head(): MetaInfo {
        const image =
            this.mode === 'ext' ? this.$assets.illustration.cta.extension : this.$assets.illustration.cta.app
        const image2 = '/assets/svg/bg-extension.svg'
        return {
            link: [
                {
                    rel: 'preload',
                    as: 'image',
                    href: image,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                            ? '(min-width: 1024px)'
                            : ''
                        : '',
                },
                {
                    rel: 'preload',
                    as: 'image',
                    href: image2,
                    media: !(this.cta.mobile && this.cta.desktop)
                        ? this.cta.mobile
                            ? '(max-width: 1023px)'
                            : this.cta.desktop
                            ? '(min-width: 1024px)'
                            : ''
                        : '',
                },
            ],
        } as MetaInfo
    },

    computed: {
        text(): { head: string; title: string; description?: string } {
            if (this.mode === 'ext') {
                return {
                    head: this.extHead,
                    title: this.extTitle,
                    description: this.extDescription,
                }
            } else {
                return {
                    head: this.appHead,
                    title: this.appTitle,
                }
            }
        },
        imageWidth(): number {
            return this.mode === 'ext' ? 52 : 48
        },
    },
    methods: {
        hideCard() {
            this.hide = true
        },
    },
})
