
import Vue from 'vue'

export default Vue.extend({
    name: 'FormFieldGeneric',
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: String,
        },
        invalid: {
            type: [Boolean, String],
            default: false,
        },
        bgcolor: {
            type: String,
        },
        image: {
            type: String,
        },
        imagestyle: {
            type: String,
            default: 'top-3',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        focus() {
            ;(this.$refs.input as HTMLInputElement).focus()
        },
        blur() {
            ;(this.$refs.input as HTMLInputElement).blur()
        },
        select() {
            ;(this.$refs.input as HTMLInputElement).select()
        },
        setValue(value: string) {
            ;(this.$refs.input as HTMLInputElement).value = value
        },
    },
})
