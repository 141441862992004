import { render, staticRenderFns } from "./Homepage.vue?vue&type=template&id=7f50cae3&scoped=true&"
import script from "./Homepage.vue?vue&type=script&lang=ts&"
export * from "./Homepage.vue?vue&type=script&lang=ts&"
import style0 from "./Homepage.vue?vue&type=style&index=0&id=7f50cae3&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f50cae3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CardMainGlobalFeatured: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/card/main/GlobalFeatured.vue').default,MicroSliderItem: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/micro/SliderItem.vue').default,MicroSlider: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/micro/Slider.vue').default,MicroTabs: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/micro/Tabs.vue').default,CtaHandler: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/cta/Handler.vue').default,CardMainGlobal: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/card/main/Global.vue').default,MicroLazy: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/micro/Lazy.vue').default,BtnSeeMoreResults: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/btn/SeeMoreResults.vue').default,CardImagesGallery: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/card/ImagesGallery.vue').default,MicroCategoriesChips: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/micro/CategoriesChips.vue').default,SidebarBanners: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/sidebar/Banners.vue').default,SidebarFeaturedCoupons: require('/home/cleavr/www.jdescuentos.cl/releases/20240822032920153/components/sidebar/FeaturedCoupons.vue').default})
